/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { common } from '@/api/common'
import { equipment } from '@/api/equipment'

export interface CommonState {
  houseAreas: any[]
  doorGroups: any[]
  carGroups: any[]
  deviceGroups: any[]
}

@Module({ dynamic: true, store, name: 'common' })
class Common extends VuexModule implements CommonState {
  $message: any
  public houseAreas: any[] = []
  public doorGroups: any[] = []
  public carGroups: any[] = []
  public deviceGroups: any[] = []

  @Mutation
  private CHANGE_SETTING(payload: { key: string; value: any }) {
    const { key, value } = payload
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      ;(this as any)[key] = value
    }
  }
  @Action
  public ChangeSetting(payload: { key: string; value: any }) {
    this.CHANGE_SETTING(payload)
  }

  @Mutation
  private SET_HOUSEAREAS(areas: any) {
    this.houseAreas = areas
  }
  @Mutation
  private SET_DOORGROUPS(doors: any) {
    this.doorGroups = doors
  }
  @Mutation
  private SET_CARGROUPS(cars: any) {
    this.carGroups = cars
  }
  @Mutation
  private SET_DEVICEGROUPS(devices: any) {
    this.deviceGroups = devices
  }

  // 获取房屋期数
  @Action
  public GetHouseAreas() {
    common.getAreaList().then((res: any) => {
      if (res.data.code === '200') {
        const data = res.data.data
        this.SET_HOUSEAREAS(data)
      } else {
        this.$message.error(res.data.message)
      }
    })
  }
  // 获取门禁组
  @Action
  public GetDoorGroups() {
    common
      .getAuthGroups({
        size: '-1',
        type: 1
      })
      .then((res: any) => {
        if (res.data.code === '200') {
          const data = res.data.data
          this.SET_DOORGROUPS(data)
        } else {
          this.$message.error(res.data.message)
        }
      })
  }
  // 获取车闸组
  @Action
  public GetCarGroups() {
    common
      .getAuthGroups({
        type: 2,
        size: '-1'
      })
      .then((res: any) => {
        if (res.data.code === '200') {
          const data = res.data.data
          this.SET_CARGROUPS(data)
        } else {
          this.$message.error(res.data.message)
        }
      })
  }
  // 获取设备组
  @Action
  public GetDeviceGroups() {
    equipment.getDeviceGroups({ size: '-1' }).then((res: any) => {
      if (res.data.code === '200') {
        const data = res.data.data
        this.SET_DEVICEGROUPS(data)
      } else {
        this.$message.error(res.data.message)
      }
    })
  }
}

export const CommonModule = getModule(Common)
