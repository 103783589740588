


































































































































import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import Dialog from '@/components/Dialog/index.vue'
import { Select, Option, Upload, Checkbox, CheckboxGroup } from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
@Component({
  components: {
    Dialog
  }
})
export default class AddInfo extends Vue {
  $constants: any
  // 展示类型
  showTypeDicts: any = []
  isHover = false
  isAddDepShow = false
  loading = false
  addLoading = false
  newDepart = ''
  sraffInfo = {
    id: '',
    name: '',
    deptId: '',
    faceImg: '',
    deviceGroupIds: [] as any
  }
  showCoverImage = ''
  checkAll = false
  defaultCheckedKeys = [] as any
  // 部门列表
  departmentList = [] as any

  staffInfoRules = {
    name: [
      { max: 20, message: '长度在20个字符', trigger: 'blur' },
      { required: true, message: '请填写姓名', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z\u4e00-\u9fa5]+$/,
        message: '请输入中文、英文或数字',
        trigger: 'blur'
      }
    ],
    deptId: [{ required: true, message: '请选择部门', trigger: 'change' }],
    faceImg: [{ required: true, message: '请上传照片', trigger: 'blur' }],
    deviceGroupIds: [{ required: true, message: '请选择门禁', trigger: 'blur' }]
  }

  // 获取设备组数据
  get deviceGroups() {
    return CommonModule.deviceGroups
  }

  beforeCreate() {
    CommonModule.GetDeviceGroups()
  }

  mounted() {
    this.getDepartmentList()
    if (this.$route.query.id) {
      this.getStaffInfo(this.$route.query.id)
    }
  }

  getDepartmentList() {
    this.$api.property.getDepartmentList({ size: -1 }).then((res: any) => {
      if (res.data.success && res.data.code === '200') {
        this.departmentList = res.data.data
      }
    })
  }

  getStaffInfo(id: any) {
    this.$api.property.getStaffInfo(id).then((res: any) => {
      if (res.data.success && res.data.code === '200') {
        this.showCoverImage =
          this.$constants.common.imgPrefix + res.data.data.faceImg
        this.sraffInfo = res.data.data
      }
    })
  }

  save() {
    ;(this.$refs['staffinfo'] as any).validate((valid: any) => {
      if (valid) {
        this.loading = true
        this.$api.property
          .addEditStaff(this.sraffInfo)
          .then((res: any) => {
            this.loading = false
            if (res.data.success && res.data.code === '200') {
              this.$message.success('新增物业人员成功')
              this.goback()
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    })
  }

  closeDialog() {
    this.newDepart = ''
    this.isAddDepShow = false
  }

  dialogConfirm() {
    if (this.newDepart === '') {
      return this.$message.warning('请填写部门名称')
    }
    this.addLoading = true
    this.$api.property
      .addNewDepartment({ name: this.newDepart })
      .then((res: any) => {
        this.addLoading = false
        if (res.data.success && res.data.code === '200') {
          this.isAddDepShow = false
          this.newDepart = ''
          this.getDepartmentList()
        }
      })
      .catch(() => {
        this.addLoading = false
      })
  }

  // 上传成功
  uploadSuccess(res: any) {
    this.sraffInfo.faceImg = res.data
  }
  handleAvatarChange(file: any) {
    this.showCoverImage = URL.createObjectURL(file.raw)
  }

  // 上传前
  beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    const isPNG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isJPG && !isJPEG && !isPNG) {
      this.$message.error('上传照片只能是 JPG/JPEG/PNG 格式!')
    }
    if (!isLt2M) {
      this.$message.error('上传单张照片大小不能超过 2MB!')
    }
    return (isJPG || isJPEG || isPNG) && isLt2M
  }

  handleCheckAllChange(val: boolean) {
    this.sraffInfo.deviceGroupIds = val
      ? this.deviceGroups.map((v: any) => {
          return v.id
        })
      : []
  }

  handleCheckedDoorsChange(value: any) {
    const checkedCount = value.length
    this.checkAll = checkedCount === this.deviceGroups.length
  }

  goback() {
    this.$router.push('/property/staff')
  }
}
